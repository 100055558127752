<template>
  <ContentWrapper class="workorder-form">
    <h4>{{ id ? `${$t('system.edit_workorder')} #${id}` : $t('system.create_work_order') }}</h4>
    <el-divider />
    <el-form
        :model="form"
        :size="size"
        label-position="top"
        ref="form"
        v-loading="preloading"
    >
      <div class="row">
        <div class="col-lg-3">
          <el-form-item :label="$t('system.po')">
            <el-input
                :class="{'validate-error': !!getBackendErrors('po')}"
                v-mask="'#################'"
                v-model.number="form.po"
            />
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('po')"
            >
              {{ $t(getBackendErrors('po')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.client')"
              required
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('account_id')}"
                :placeholder="$t('system.enter_3_or_more_characters')"
                :remote-method="fetchAccounts"
                @change="fetchContacts"
                filterable
                remote
                v-model="form.account_id"
            >
              <el-option
                  :key="account.id"
                  :label="account.name"
                  :value="account.id"
                  v-for="account in accounts"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('account_id')"
            >
              {{ $t(getBackendErrors('account_id')[0]) }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('system.contact')">
            <el-select
                :class="{'validate-error': !!getBackendErrors('contact_id')}"
                :disabled="!form.account_id"
                filterable
                v-model="form.contact_id"
            >
              <el-option
                  :key="contact.id"
                  :label="contact.name"
                  :value="contact.id"
                  v-for="contact in contacts"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('contact_id')"
            >
              {{ $t(getBackendErrors('contact_id')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.agent')"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('agents_ids')}"
                filterable
                multiple
                v-model="form.agents_ids"
            >
              <el-option
                  :key="agent.id"
                  :label="agent.name"
                  :style="{background: agent.color}"
                  :value="agent.id"
                  v-for="agent in agents"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('agents_ids')"
            >
                <span
                    :key="idx"
                    v-for="(part, idx) in getBackendErrors('agents_ids')[0].split(': ')"
                >
                  {{ $t(part) }}{{ getBackendErrors('agents_ids')[0].split(': ').length > 1 ? ':' : '' }}
                </span>
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.status')"
              required
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('status_id')}"
                filterable
                v-model="form.status_id"
            >
              <el-option
                  :key="status.id"
                  :label="$t(`system.${status.name}`)"
                  :value="status.id"
                  v-for="status in statuses"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('status_id')"
            >
              {{ $t(getBackendErrors('status_id')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.priority')"
              required
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('priority_id')}"
                filterable
                v-model="form.priority_id"
            >
              <el-option
                  :key="priority.id"
                  :label="priority.name"
                  :value="priority.id"
                  v-for="priority in priorities"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('priority_id')"
            >
              {{ $t(getBackendErrors('priority_id')[0]) }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('system.not_to_exceed')">
            <el-input
                :class="{'validate-error': !!getBackendErrors('nte')}"
                type="number"
                v-model="form.nte"
            >
              <span slot="prefix">USD</span>
            </el-input>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('nte')"
            >
              {{ $t(getBackendErrors('nte')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div class="col-lg-3">
          <el-form-item
              :label="$t('system.type')"
              required
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-radio-group v-model="form.is_subcontracted">
              <el-radio-button
                  :label="true"
                  class="mb-0"
              >{{ $t('system.subcontractor') }}
              </el-radio-button>
              <el-radio-button
                  :label="false"
                  class="mb-0"
              >{{ $t('system.technicians') }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <template v-if="!form.is_subcontracted">
            <el-form-item
                :label="$t('system.manager')"
                required
            >
              <el-select
                  :class="{'validate-error': !!getBackendErrors('managers_ids')}"
                  filterable
                  multiple
                  v-model="form.managers_ids"
              >
                <el-option
                    :key="manager.id"
                    :label="manager.name"
                    :style="{background: manager.color}"
                    :value="manager.id"
                    v-for="manager in managers"
                />
              </el-select>
              <div
                  class="el-form-item__error"
                  v-if="getBackendErrors('managers_ids')"
              >
                <span
                    :key="idx"
                    v-for="(part, idx) in getBackendErrors('managers_ids')[0].split(':')"
                >
                  {{ $t(part) }}{{ getBackendErrors('managers_ids')[0].split(': ').length > 1 ? ':' : '' }}
                </span>
              </div>
            </el-form-item>
            <el-form-item
                :label="$t('system.technicians')"
                required
                v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
            >
              <el-select
                  :class="{'validate-error': !!getBackendErrors('technicians_ids')}"
                  filterable
                  multiple
                  v-model="form.technicians_ids"
              >
                <el-option
                    :key="tech.id"
                    :label="tech.name"
                    :style="{background: tech.color}"
                    :value="tech.id"
                    v-for="tech in technicians"
                />
              </el-select>
              <div
                  class="el-form-item__error"
                  v-if="getBackendErrors('technicians_ids')"
              >
                <span
                    :key="idx"
                    v-for="(part, idx) in getBackendErrors('technicians_ids')[0].split(': ')"
                >
                  {{ $t(part) }}{{ getBackendErrors('technicians_ids')[0].split(': ').length > 1 ? ':' : '' }}
                </span>
              </div>
            </el-form-item>
          </template>
          <el-form-item
              :label="$t('system.subcontractor')"
              required
              v-else
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('subcontractor_id')}"
                filterable
                v-model="form.subcontractor_id"
            >
              <el-option
                  :key="subcontractor.id"
                  :label="subcontractor.name"
                  :style="{background: subcontractor.color}"
                  :value="subcontractor.id"
                  v-for="subcontractor in subcontractors"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('subcontractor_id')"
            >
                <span
                    :key="idx"
                    v-for="(part, idx) in getBackendErrors('subcontractor_id')[0].split(': ')"
                >
                  {{ $t(part) }}{{ getBackendErrors('subcontractor_id')[0].split(': ').length > 1 ? ':' : '' }}
                </span>
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.tools')"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('tools_list')}"
                :placeholder="$t('system.select_or_add_new')"
                :remote-method="fetchTools"
                allow-create
                filterable
                multiple
                remote
                v-model="form.tools_list"
            >
              <el-option
                  :key="tool.id"
                  :label="tool.name"
                  :value="tool.name"
                  v-for="tool in tools"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('tools_ids')"
            >
              {{ $t(getBackendErrors('tools_ids')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.equipment')"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('equipments_ids')}"
                :placeholder="$t('system.select_or_add_new')"
                :remote-method="fetchEquipments"
                allow-create
                filterable
                multiple
                remote
                v-model="form.equipment_list"
            >
              <el-option
                  :key="equipment.id"
                  :label="equipment.name"
                  :value="equipment.name"
                  v-for="equipment in equipments"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('equipment_list')"
            >
              {{ $t(getBackendErrors('equipment_list')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.products')"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('products_ids')}"
                :placeholder="$t('system.select_or_add_new')"
                :remote-method="fetchProducts"
                allow-create
                filterable
                multiple
                remote
                v-model="form.products_list"
            >
              <el-option
                  :key="product.id"
                  :label="product.name"
                  :value="product.name"
                  v-for="product in products"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('products_list')"
            >
              {{ $t(getBackendErrors('products_list')[0]) }}
            </div>
          </el-form-item>
          <el-form-item
              :label="$t('system.vehicles')"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('vehicles_list')}"
                :placeholder="$t('system.select_or_add_new')"
                :remote-method="fetchVehicles"
                filterable
                multiple
                remote
                v-model="form.vehicles_list"
            >
              <el-option
                  :key="vehicle.id"
                  :label="vehicle.name"
                  :value="vehicle.name"
                  v-for="vehicle in vehicles"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('vehicles_list')"
            >
              <span
                  :key="idx"
                  v-for="(part, idx) in getBackendErrors('vehicles_list')[0].split(':')"
              >
                {{ $t(part) }}{{ getBackendErrors('vehicles_list')[0].split(': ').length > 1 ? ':' : '' }}
              </span>
            </div>
          </el-form-item>
        </div>
        <div
            class="col-lg-6"
            :class="{'mt-3': ifHasPermission(['wo-contractor'])}"
        >
          <div class="row">
            <!--            <div class="col-4">-->
            <!--              -->
            <!--            </div>-->
            <div
                class="col"
            >
              <el-form-item
                  :label="$t('system.start_at')"
                  :required="!ifHasPermission(['wo-contractor']) || !!user.admin"
              >
                <el-date-picker
                    :class="{'validate-error': !!getBackendErrors('start_at')}"
                    :picker-options="startAtPickerOptions"
                    :placeholder="$t('system.pick_a_day')"
                    type="date"
                    v-model="form.start_at"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('start_at')"
                >
                  {{ $t(getBackendErrors('start_at')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div
                class="col"
            >
              <el-form-item
                  :label="$t('system.done_at')"
                  :required="!ifHasPermission(['wo-contractor']) || !!user.admin"
              >
                <el-date-picker
                    :class="{'validate-error': !!getBackendErrors('done_at')}"
                    :picker-options="doneAtPickerOptions"
                    :placeholder="$t('system.pick_a_day')"
                    type="date"
                    v-model="form.done_at"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <small
                    v-if="getError('done_at')"
                    style="color: #b00000"
                >{{ $t('system.invalid_date_range') }}</small>
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('done_at')"
                >
                  {{ $t(getBackendErrors('done_at')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div
                class="col"
            >
              <el-form-item :label="$t('system.estimation')">
                <HoursMinutesInput
                    :class="{'validate-error': !!getBackendErrors('estimation')}"
                    size="mini"
                    v-model="form.estimation"
                />
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('estimation')"
                >
                  {{ $t(getBackendErrors('estimation')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div
                class="col-12"
            >
              <el-form-item
                  :label="$t('system.arrival_time_range')"
                  :required="!ifHasPermission(['wo-contractor']) || !!user.admin"
              >
                <div class="row">
                  <div class="col-4">
                    <el-date-picker
                        :class="{'w-100': true, 'validate-error': !!getBackendErrors('arrival_start') || !!getBackendErrors('arrival_end')}"
                        :picker-options="arrivalDatePickerOptions"
                        :placeholder="$t('system.pick_a_day')"
                        type="date"
                        v-model="arrivalDate"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <small
                        v-if="getError('arrivalDate')"
                        style="color: #b00000"
                    >{{ $t('system.date_not_in_range') }}</small>
                  </div>
                  <div
                      :class="{'validate-error': !!getBackendErrors('arrival_start') || !!getBackendErrors('arrival_end')}"
                      class="col-8"
                  >
                    <el-time-picker
                        :class="{'w-100': true}"
                        :disabled="!arrivalDate"
                        :end-placeholder="$t('system.end_time')"
                        :start-placeholder="$t('system.start_time')"
                        is-range
                        :picker-options="{
                          format: 'hh:mm A'
                        }"
                        format="hh:mm A"
                        range-separator="-"
                        v-model="arrivalTimeRange"
                    />
                    <small
                        v-if="getError('arrivalTimeRange')"
                        style="color: #b00000"
                    >{{ $t('system.invalid_time_range') }}</small>
                  </div>
                  <div
                      class="el-form-item__error"
                      v-if="getBackendErrors('arrival_start') || getBackendErrors('arrival_end')"
                  >
                    {{ $t(getBackendErrors('arrival_start')[0]) || $t(getBackendErrors('arrival_end')[0]) }}
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="col-6">
              <el-form-item :label="$t('system.pictures')">
                <el-upload
                    :action="picturesUploadURL"
                    :auto-upload="false"
                    :file-list="picturesToUpload"
                    :on-change="onPicturesChanges"
                    :on-remove="onPictureRemove"
                    multiple
                    ref="picturesUploader"
                >
                  <el-button
                      size="mini"
                      slot="trigger"
                      type="info"
                  >
                    {{ $t('system.select_pictures') }}
                  </el-button>
                </el-upload>
                <FilesList
                    :downloadProgress="pictureDownloadProgress"
                    :files="pictures"
                    @download="downloadPicture"
                    @remove="removePicture"
                    download
                    imageType
                    remove
                />
              </el-form-item>
            </div>
            <div class="col-6">
              <el-form-item :label="$t('system.documents')">
                <el-upload
                    :action="documentsUploadURL"
                    :auto-upload="false"
                    :file-list="documentsToUpload"
                    :on-change="onDocumentsChanges"
                    :on-remove="onDocumentRemove"
                    multiple
                    ref="documentsUploader"
                >
                  <el-button
                      size="mini"
                      slot="trigger"
                      type="info"
                  >
                    {{ $t('system.select_documents') }}
                  </el-button>
                </el-upload>
                <FilesList
                    :downloadProgress="documentDownloadProgress"
                    :files="documents"
                    @download="downloadDocument"
                    @remove="removeDocument"
                    download
                    remove
                />
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <el-form-item :label="$t(`system.note_for_${form.is_subcontracted ? 'subcontractor' : 'technician'}`)">
                <el-input
                    :class="{'validate-error': !!getBackendErrors('note_for_technician')}"
                    :rows="3"
                    type="textarea"
                    v-model="form.note_for_technician"
                />
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('note_for_technician')"
                >
                  {{ $t(getBackendErrors('note_for_technician')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div
                class="col-6"
                v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
            >
              <el-form-item :label="$t(`system.completion_note`)">
                <el-input
                    :class="{'validate-error': !!getBackendErrors('completion_note')}"
                    :rows="3"
                    type="textarea"
                    v-model="form.completion_note"
                />
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('completion_note')"
                >
                  {{ $t(getBackendErrors('completion_note')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div
                class="col-6"
                v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
            >
              <el-form-item :label="$t(`system.note_to_customer`)">
                <el-input
                    :class="{'validate-error': !!getBackendErrors('note_to_customer')}"
                    :rows="3"
                    type="textarea"
                    v-model="form.note_to_customer"
                />
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('note_to_customer')"
                >
                  {{ $t(getBackendErrors('note_to_customer')[0]) }}
                </div>
              </el-form-item>
            </div>
            <div class="col-6">
              <el-form-item :label="$t('system.description')">
                <el-input
                    :class="{'validate-error': !!getBackendErrors('description')}"
                    :rows="3"
                    type="textarea"
                    v-model="form.description"
                />
                <div
                    class="el-form-item__error"
                    v-if="getBackendErrors('description')"
                >
                  {{ $t(getBackendErrors('description')[0]) }}
                </div>
              </el-form-item>
            </div>
          </div>
          <el-form-item class="d-flex justify-content-end">
            <el-button
                :loading="saveLoading"
                @click="onSave"
                type="primary"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button
                :disabled="saveLoading"
                @click="id ? $router.push({name: 'WorkOrder', params: {id}}) : $router.push({name: 'WorkOrdersList'})"
            >
              {{ $t('system.cancel') }}
            </el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import {cloneDeep, remove} from 'lodash';
import moment from 'moment';
import FilesList from '../../components/FilesList/FilesList.vue';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import {HHHmmToHHmmss, HHmmssToHHHmm} from '@/helpers/time';
import notification from '../../notification/notify';
import {checkFileSize, downloadFile, isImage} from '@/helpers/files';
import {mapActions, mapState} from 'vuex';
import HoursMinutesInput from '@/components/HoursMinutesInput';

export default {
  name: 'Form',
  components: {
    HoursMinutesInput,
    ContentWrapper,
    FilesList,
  },

  props: ['id'],

  data() {
    return {
      size: 'mini',
      arrivalDate: null,
      validationErrors: [],
      form: null,
      maxUploadSizeMB: 2,
      headers: {Authorization: 'Bearer ' + localStorage.getItem('token')},
      //---pictures---
      pictureDownloadProgress: {
        id: null,
        progress: 0,
      },
      picturesUploadURL: '',
      pictures: [],
      picturesToUpload: [],
      picturesToDelete: [],
      // -------------
      //--documents---
      documentDownloadProgress: {
        id: null,
        progress: 0,
      },
      documentsUploadURL: '',
      documents: [],
      documentsToUpload: [],
      documentsToDelete: [],
      //--------------
      vehicles: [],
      accounts: [],
      managers: [],
      statuses: [],
      contacts: [],
      priorities: [],
      technicians: [],
      agents: [],
      subcontractors: [],
      equipments: [],
      products: [],
      tools: [],
      loading: false,
      preloading: false,
      loadings: {
        accounts: false,
        managers: false,
        orders: false,
        technicians: false,
      },
      backendErrors: {},
      saveLoading: false,
      arrivalDatePickerOptions: {
        disabledDate: (date) => {
          return this.form.start_at && this.form.done_at ? date.getTime() < moment(this.form.start_at.split(' ')[0]) || date.getTime() > moment(this.form.done_at.split(' ')[0]) : null;
        },
      },
      startAtPickerOptions: {
        disabledDate: (date) => {
          return this.form.done_at ? date.getTime() > moment(this.form.done_at.split(' ')[0]) : null;
        },
      },
      doneAtPickerOptions: {
        disabledDate: (date) => {
          return this.form.start_at ? date.getTime() < moment(this.form.start_at.split(' ')[0]) : null;
        },
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.params.id === to.params.id && to.params.id) {
        vm.fetchEditData();
        vm.fetchDocuments();
        vm.fetchImages();

        vm.picturesToDelete = [];
        vm.picturesToUpload = [];

        vm.documentsToDelete = [];
        vm.documentsToUpload = [];
      }
    });
  },

  mounted() {
    if (this.id) {
      this.fetchEditData();
    }
    this.fetchManagers();
    this.fetchTechnicians();
    this.fetchAgents();
    this.fetchSubcontractors();
    this.fetchStatuses();
    this.fetchPriorities();
    this.fetchEquipments();
    this.fetchProducts();
    this.fetchVehicles();
    this.fetchTools();
    if (this.id) {
      this.fetchDocuments();
      this.fetchImages();
    }
    this.form = this.prepareForm();
    this.createForContractor();
  },

  computed: {
    ...mapState('auth', ['user']),
    arrivalTimeRange: {
      get() {
        if (this.form.arrival_start && this.form.arrival_start) {
          return [this.form.arrival_start, this.form.arrival_end];
        }
        return null;
      },

      set(value) {
        if (value) {
          const [start, end] = value;
          const startDate = moment(this.arrivalDate);
          const endDate = moment(this.arrivalDate);
          this.form.arrival_start = startDate
              .hours(start.getHours())
              .minutes(start.getMinutes())
              .format('YYYY-MM-DD HH:mm:ss');
          this.form.arrival_end = endDate
              .hours(end.getHours())
              .minutes(end.getMinutes())
              .format('YYYY-MM-DD HH:mm:ss');
        } else {
          this.form.arrival_start = null;
          this.form.arrival_end = null;
        }
      },
    },
  },

  methods: {
    ...mapActions('workOrders', ['removeTab']),

    createForContractor() {
      if (this.user && this.user.account_id) {
        this.form.account_id = this.user.account_id;
        this.fetchContacts(this.user.account_id);
      }
    },

    async fetchDocuments() {
      try {
        const {data} = await axios.get(`/workorders/order/${this.id}/documents`);
        this.documents = data;
      } catch (error) {
        console.error('fetchDocumentsError', error.message);
      }
    },

    async fetchImages() {
      try {
        const {data} = await axios.get(`/workorders/order/${this.id}/images`);
        this.pictures = data.data;
      } catch (error) {
        console.error('fetchFilesError', error.message);
      }
    },

    //---pictures---
    onPictureRemove(file, fileList) {
      this.setNewFilesList(fileList, 'pictures');
    },

    onPicturesChanges(file, fileList) {
      if (!checkFileSize(file.raw, 5)) {
        notification.notify(
            file.name,
            `${file.name} ${this.$t('notify.is_too_large')}`,
            'warning');
        remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      if (!isImage(file.name)) {
        notification.notify(
            file.name,
            `${file.name} ${this.$t('notify.file_is_not_image')}`,
            'warning');
        remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      this.setNewFilesList(fileList, 'pictures');
    },

    getError(error) {
      return !!this.validationErrors.find((element) => element === error);
    },

    validDates() {
      this.validationErrors = [];
      if (this.form.start_at && this.form.done_at && new Date(this.form.start_at.substr(0, 10)) > new Date(this.form.done_at.substr(0, 10))) {
        this.validationErrors.push('done_at');
      }
      if (this.form.arrivalDate && this.form.start_at && new Date(this.form.arrivalDate.substr(0, 10)) < new Date(this.form.start_at.substr(0, 10))) {
        this.validationErrors.push('arrivalDate');
      }
      if (this.form.arrivalDate && this.form.done_at && new Date(this.form.arrivalDate.substr(0, 10)) > new Date(this.form.done_at.substr(0, 10))) {
        this.validationErrors.push('arrivalDate');
      }
      if (this.arrivalTimeRange && Array.isArray(this.arrivalTimeRange) && this.arrivalTimeRange.length > 1 && new Date(this.arrivalTimeRange[0]) > new Date(this.arrivalTimeRange[1])) {
        this.validationErrors.push('arrivalTimeRange');
      }
      return !!this.validationErrors.length;
    },

    async uploadPictures(orderId) {
      const requests = this.picturesToUpload.map(async file => {
        const formData = new FormData();
        formData.append('file', file.raw);
        return axios.post(
            `/workorders/order/${orderId}/attachment`,
            formData,
            {headers: {'Content-Type': `multipart/form-data`}});
      });
      return await Promise.all(requests);
    },

    removePicture(file) {
      this.pictures.splice(this.pictures.findIndex(item => item.id === file.id), 1);
      this.picturesToDelete.push(file.id);
    },

    async removePictures() {
      const requests = this.picturesToDelete.map(async item => {
        return axios.delete(`workorders/attachment/${item}`);
      });

      return await Promise.all(requests);
    },

    async downloadPicture(item) {
      this.pictureDownloadProgress.id = item.id;
      const file = await axios.get(`/workorders/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.pictureDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.pictureDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },

    //--------------
    //--documents---
    onDocumentsChanges(file, fileList) {
      if (!checkFileSize(file, 5)) {
        notification.notify(
            file.name,
            this.$t('notify.file_is_too_large'),
            'warning');
        remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      if (isImage(file.filename)) {
        notification.notify(
            file.name,
            this.$t('notify.file_is_image'),
            'warning');
        remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      this.setNewFilesList(fileList, 'documents');
    },

    onDocumentRemove(file, fileList) {
      this.setNewFilesList(fileList, 'documents');
    },

    async uploadDocuments(orderId) {
      const requests = this.documentsToUpload.map(async file => {
        const formData = new FormData();
        formData.append('file', file.raw);
        return axios.post(
            `/workorders/order/${orderId}/attachment`,
            formData,
            {headers: {'Content-Type': `multipart/form-data`}});
      });
      return await Promise.all(requests);
    },

    removeDocument(file) {
      this.documents.splice(this.documents.findIndex(item => item.id === file.id), 1);
      this.documentsToDelete.push(file.id);
    },

    async removeDocuments() {
      const requests = this.documentsToDelete.map(async item => {
        return axios.delete(`workorders/attachment/${item}`);
      });

      return await Promise.all(requests);
    },

    async downloadDocument(item) {
      this.documentDownloadProgress.id = item.id;
      const file = await axios.get(`/workorders/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.documentDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.documentDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },

    //--------------
    setNewFilesList(fileList, type) {
      this[`${type}ToUpload`] = cloneDeep(fileList);
    },

    async fetchEditData() {
      this.preloading = true;
      try {
        const {data} = await axios.get(`workorders/order/${this.id}/edit`);
        const additionalData = await this.fetchAdditionalData(data.data);
        this.setAdditionalData(additionalData);
        data.data.estimation = HHmmssToHHHmm(data.data.estimation);
        this.form = this.mapForm(this.form, data.data);
        this.arrivalDate = this.form.arrival_start;
        if (this.form.account_id) {
          await this.fetchContacts(this.form.account_id);
        }
      } catch (e) {
        console.error('error', e);
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.preloading = false;
      }
    },

    setAdditionalData(data) {
      Object.keys(data).forEach(key => {
        this[key] = data[key];
      });
    },

    mapForm(form, newData) {
      return Object.keys(newData)
          .filter(key => form.hasOwnProperty(key))
          .reduce((obj, key) => {
            obj[key] = newData[key];
            return obj;
          }, {});
    },

    async fetchAdditionalData(formData) {
      const calls = {};
      if (formData.account_id) {
        calls.accounts = this.fetchOptionsByIds('workorders/accounts', [formData.account_id]);
      }
      if (formData.account_id && formData.contact_id) {
        calls.contacts = this.fetchOptionsByIds(`workorders/contacts/${formData.account_id}`, [formData.contact_id]);
      }
      const promises = await Promise.all(Object.values(calls));
      const response = {};
      Object.keys(calls).forEach((key, idx) => {
        response[key] = promises[idx];
      });
      return response;
    },

    getBackendErrors(field) {
      return this.backendErrors[field];
    },

    async onSave() {
      if ((!this.ifHasPermission(['wo-contractor']) || !!this.user.admin) && this.validDates()) {
        console.error('return');
        return;
      }
      this.saveLoading = true;
      try {
        const formClone = {...this.form};

        if (this.form.estimation) {
          formClone.estimation = HHHmmToHHmmss(formClone.estimation);
        }
        let method = 'post';
        let url = '/workorders/order';
        if (this.id) {
          method = 'put';
          url = `workorders/order/${this.id}`;
        }
        const {data} = await axios[method](url, formClone);
        // this.generateUploadPicturesURL()
        await this.uploadPictures(data.id);
        await this.removePictures();
        await this.uploadDocuments(data.id);
        await this.removeDocuments();
        await this.$router.push({
          name: 'WorkOrder',
          params: {id: data.id},
        });
        if (!this.id) {
          this.removeTab({id: 'create'});
        }
        this.form = this.prepareForm();
        this.createForContractor();
      } catch (error) {
        this.backendErrors = error.response.data;
      } finally {
        this.saveLoading = false;
      }
    },

    prepareForm() {
      return {
        agents_ids: [],
        po: '',
        nte: null,
        account_id: null,
        contact_id: null,
        managers_ids: null,
        status_id: null,
        priority_id: null,
        arrival_start: null,
        arrival_end: null,
        done_at: !this.ifHasPermission(['wo-contractor']) || !!this.user.admin ? moment().format('YYYY-MM-DD 00:00:00') : null,
        start_at: !this.ifHasPermission(['wo-contractor']) || !!this.user.admin ? moment().format('YYYY-MM-DD 00:00:00') : null,
        is_subcontracted: false,
        technicians_ids: [],
        subcontractor_id: null,
        vehicles_list: [],
        tools_list: [],
        equipment_list: [],
        products_list: [],
        description: '',
        localizations: '',
        estimation: '',
        note_for_technician: '',
        completion_note: '',
        note_to_customer: '',
      };
    },

    async fetchRemoteOptions(val, url, storeIn, minLength = 3, destructFromDataObject = false) {
      if (val.length >= minLength) {
        this.loading = true;
        const data = await this.fetchOptions(url, val);
        this[storeIn] = data.data || data;
        this.loading = false;
      } else {
        this[storeIn] = [];
      }
    },

    async fetchEquipments(val = '') {
      await this.fetchRemoteOptions(val, 'workorders/equipments', 'equipments', 0, true);
    },

    async fetchProducts(val = '') {
      await this.fetchRemoteOptions(val, 'workorders/products', 'products', 0, true);
    },

    async fetchVehicles(val = '') {
      await this.fetchRemoteOptions(val, 'workorders/vehicles', 'vehicles', 0, true);
    },

    async fetchTools(val = '') {
      await this.fetchRemoteOptions(val, 'workorders/tools', 'tools', 0, true);
    },

    async fetchStatuses() {
      this.statuses = await this.fetchOptions('workorders/statuses');
    },

    async fetchPriorities() {
      this.priorities = await this.fetchOptions('workorders/priorities');
    },

    async fetchAccounts(val) {
      await this.fetchRemoteOptions(val, 'workorders/accounts', 'accounts');
    },

    async fetchContacts(accountId) {
      this.contacts = await this.fetchOptions(`workorders/contacts/${accountId}`);
      // await this.fetchRemoteOptions(val, `workorders/contacts/${this.form.account_id}`, 'accounts')
    },

    async fetchManagers() {
      this.managers = await this.fetchOptions('workorders/project_managers');
    },

    async fetchTechnicians() {
      this.technicians = await this.fetchOptions('workorders/technicians');
    },

    async fetchAgents() {
      this.agents = await this.fetchOptions('workorders/agents');
    },

    async fetchSubcontractors() {
      this.subcontractors = await this.fetchOptions('workorders/subcontractors');
    },

    async fetchOptionsByIds(url, ids = []) {
      try {
        const {data} = await axios.get(url, {params: {ids}});
        return data;
      } catch (e) {
        //
      }
    },

    async fetchOptions(url, search) {
      try {
        const {data} = await axios.get(url, {params: {search}});
        return data.data || data;
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.workorder-form {
  .recurring-dialog {
    .el-dialog__body {
      padding-bottom: 5px;
    }
  }

  .el-form {
    .el-form-item__label {
      padding: 0;
    }

    .el-input, .el-select {
      width: 100%
    }
  }
}
</style>
