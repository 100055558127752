<template>
  <div class="d-flex">
    <el-input
        :size="size"
        @input="emit"
        class="mr-1"
        placeholder="000"
        v-bind="$attrs"
        v-mask="'###'"
        v-model="hours"
    />
    h
    <el-input
        :size="size"
        @input="emit"
        class="mr-1 ml-3"
        placeholder="00"
        v-bind="$attrs"
        v-mask="'##'"
        v-model="minutes"
    />
    m
  </div>
</template>

<script>
export default {
  name: 'HoursMinutesInput',
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
    },
  },
  data: () => ({
    hours: '',
    minutes: '',
  }),
  watch: {
    value(value) {
      const [hours, minutes] = value.split(':');
      this.hours = Number(hours).toString() || '';
      this.minutes = Number(minutes).toString() || '';
    },
  },
  methods: {
    emit() {
      this.$emit('input', `${this.hours.padStart(3, '0')}:${this.minutes.padStart(2, '0')}`);
    },
  },
};
</script>

<style scoped>

</style>
